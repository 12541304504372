import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ControlledCarousel from "../components/carousel"
import {Button, Card, Row, Col} from 'react-bootstrap'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ControlledCarousel />

    <Row>
      <Col>
          <Card style={{ marginTop: `1rem`}}>
              <div className={`card-img-top`}>
                  <Image alt="Gatsby in Space" filename="card-img.jpg" />
              </div>
              <Card.Body>
                  <Card.Title>Card Title</Card.Title>
                  <Card.Text>
                      Some quick example text to build on the card title and make up the bulk of
                      the card's content.
                  </Card.Text>
                  <Button variant="primary">Go somewhere</Button>
              </Card.Body>
          </Card>
      </Col>
      <Col>
          <Card style={{ marginTop: `1rem`}}>
              <div className={`card-img-top`}>
                  <Image alt="Gatsby in Space" filename="card-img.jpg" />
              </div>
              <Card.Body>
                  <Card.Title>Card Title</Card.Title>
                  <Card.Text>
                      Some quick example text to build on the card title and make up the bulk of
                      the card's content.
                  </Card.Text>
                  <Button variant="primary">Go somewhere</Button>
              </Card.Body>
          </Card>
      </Col>
    </Row>

      <Row>
          <Col>
              <Card style={{ marginTop: `1rem`}}>
                  <div className={`card-img-top`}>
                      <Image alt="Gatsby in Space" filename="card-img.jpg" />
                  </div>
                  <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                          Some quick example text to build on the card title and make up the bulk of
                          the card's content.
                      </Card.Text>
                      <Button variant="primary">Go somewhere</Button>
                  </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card style={{ marginTop: `1rem`}}>
                  <div className={`card-img-top`}>
                      <Image alt="Gatsby in Space" filename="card-img.jpg" />
                  </div>
                  <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                          Some quick example text to build on the card title and make up the bulk of
                          the card's content.
                      </Card.Text>
                      <Button variant="primary">Go somewhere</Button>
                  </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card style={{ marginTop: `1rem`}}>
                  <div className={`card-img-top`}>
                      <Image alt="Gatsby in Space" filename="card-img.jpg" />
                  </div>
                  <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                          Some quick example text to build on the card title and make up the bulk of
                          the card's content.
                      </Card.Text>
                      <Button variant="primary">Go somewhere</Button>
                  </Card.Body>
              </Card>
          </Col>
      </Row>


  </Layout>
)

export default IndexPage
